import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicColumnChart from '../../../components/BasicColumnChart';
import ErrorMessage from '../../../components/ErrorMessage';
import PiChartWidgetSkeleton from '../../../components/skeletons/PiChartWidgetSkeleton';
import { IServiceResponse } from '../../../hooks/HookService';

import { ReportData } from '../../../interfaces/Models';
import useVAT from '../../../hooks/useVat';

const ClinicsAmountsWidget = ({
  data,
  year,
  error,
  errorMessage,
  isLoading,
}: IServiceResponse<ReportData>) => {
  const { t } = useTranslation();
  const VAT = useVAT(year);
  if (isLoading) {
    return <PiChartWidgetSkeleton />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }

  const chartData = [
    {
      name: t('financialReports.programs.price'),
      data: Object.values(data).map((value: number) => Number(value.toFixed(2))),
    },
    {
      name: t('financialReports.programs.priceVAT'),
      data: Object.values(data).map((value: number) => Number((value * VAT).toFixed(2))),
    },
  ];

  const chartLabels = Object.keys(data).filter((key: string) => key != 'allClinics');

  const chartCategories: string[] = [...chartLabels, 'כל הקופות'];

  const shekelTooltipFormatter = (val: number) => {
    return val + t('global.currencyUnits.nis');
  };

  return (
    <BasicColumnChart
      title={t('global.dashboard.clinicsFinancialSummary')}
      chartCategories={chartCategories}
      chartData={chartData}
      yTooltipFormatter={shekelTooltipFormatter}
      enableDataLabels={false}
    />
  );
};

export default ClinicsAmountsWidget;
