import { useMemo } from 'react';
import { VAT } from '../common/Constants';
const useVAT = (year: number | undefined): number => {
  const vatRate = useMemo(() => {
    if (year && year < 2025) {
      return VAT;
    }
    return 1.18;
  }, [year]);

  return vatRate;
};

export default useVAT;
