import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CLINICS_COLORS, CLINIC_LIST } from '../../common/Constants';
import { ReportData } from '../../interfaces/Models';
import { theme } from '../../theme';
import FinancialSummary from './FinancialSummary';
import useVAT from '../../hooks/useVat';

interface IClinicsFinancialReports {
  data?: ReportData;
  isLoading: boolean;
  year?: number;
}
const ClinicsFinancialReports = ({ data, isLoading, year }: IClinicsFinancialReports) => {
  const { t } = useTranslation();

  const VAT = useVAT(year);

  return (
    <Grid container sx={{ mb: 4 }} spacing={2} justifyContent="space-between">
      {Object.values(CLINIC_LIST).map((clinicName: string) => {
        return (
          <Grid item xs={12} md={4} key={clinicName}>
            <FinancialSummary
              title={clinicName}
              value1={`${(data ? data[clinicName] || 0 : 0).toFixed(2)} ${t(
                'global.currencyUnits.nis'
              )}`}
              value2={`${((data ? data[clinicName] || 0 : 0) * VAT).toFixed(2)} ${t(
                'global.currencyUnits.nis'
              )}`}
              subTitle1={t('financialReports.programs.price')}
              subTitle2={t('financialReports.programs.priceVAT')}
              isLoading={isLoading}
              backgroundColor={CLINICS_COLORS[clinicName]}
            />
          </Grid>
        );
      })}
      <Grid item xs={12} md={4}>
        <FinancialSummary
          title="כל הקופות"
          value1={`${data?.allClinics.toFixed(2)} ${t('global.currencyUnits.nis')}`}
          value2={`${(data?.allClinics * VAT).toFixed(2)} ${t('global.currencyUnits.nis')}`}
          subTitle1={t('financialReports.programs.price')}
          subTitle2={t('financialReports.programs.priceVAT')}
          isLoading={isLoading}
          backgroundColor={theme.palette.primary.light}
        />
      </Grid>
    </Grid>
  );
};

export default ClinicsFinancialReports;
