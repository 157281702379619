import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../../components/ErrorMessage';
import PiChart from '../../../components/PiChart';
import PiChartWidgetSkeleton from '../../../components/skeletons/PiChartWidgetSkeleton';
import { IServiceResponse } from '../../../hooks/HookService';
import { ReportData } from '../../../interfaces/Models';
import { theme } from '../../../theme';
import { IChartToolTipFormatter } from './EmployeesFinancialSummaryWidget';
import useVAT from '../../../hooks/useVat';

const ClinicsFinancialSummaryWidget = ({
  data,
  year,
  error,
  errorMessage,
  isLoading,
}: IServiceResponse<ReportData>) => {
  const { t } = useTranslation();

  const VAT = useVAT(year);

  const chartToolTipFormatter = ({
    series,
    seriesIndex,
    dataPointIndex,
    w,
  }: IChartToolTipFormatter) => {
    return (
      `<div class="tooltip_box">` +
      `<span >` +
      `${chartLabels[seriesIndex]}` +
      '</span>' +
      '<hr/>' +
      `<span >` +
      `${t('financialReports.programs.price')} = ${series[seriesIndex].toFixed(2)} ${t(
        'global.currencyUnits.nis'
      )}` +
      '</span>' +
      '<div/>' +
      `<span >` +
      `${t('financialReports.programs.priceVAT')} = ${(series[seriesIndex] * VAT).toFixed(2)} ${t(
        'global.currencyUnits.nis'
      )}` +
      '</span>' +
      '</div>'
    );
  };

  if (isLoading) {
    return <PiChartWidgetSkeleton />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }

  const newData = { ...data };
  delete newData.allClinics;

  const chartData = Object.values(newData);
  const chartLabels = Object.keys(newData);

  return (
    <PiChart
      title={t('global.dashboard.clinicsFinancialSummary')}
      colors={theme.palette.dashboardCharts}
      labels={chartLabels}
      chartData={chartData}
      chartToolTipFormatter={chartToolTipFormatter}
      chartUnit={t('global.currencyUnits.nis')}
    />
  );
};

export default ClinicsFinancialSummaryWidget;
